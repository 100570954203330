<template>
  <Teleport to="body" class="default-sidebar">
    <template v-if="$route.name !== 'login' && $route.name !== 'invitation' && user">

      <!--  BEGIN TOPBAR  -->
      <top-bar-component></top-bar-component>
      <!--  END TOPBAR  -->

      <!--  BEGIN MAIN CONTAINER  -->
      <div class="main-container" id="container">

        <div class="overlay"></div>
        <div class="cs-overlay"></div>

        <!--  BEGIN SIDEBAR  -->
        <side-nav-component></side-nav-component>
        <!--  END SIDEBAR  -->

        <!--  BEGIN CONTENT PART  -->
        <div id="content" class="main-content">
          <div class="container">
            <router-view/>
          </div>
        </div>
        <!--  END CONTENT PART  -->
      </div>
      <!-- END MAIN CONTAINER -->



      <!--  BEGIN FOOTER  -->
      <footer-component></footer-component>
      <!--  END FOOTER  -->
    </template>

    <router-view v-else></router-view>
  </Teleport>
</template>

<script>
import TopBarComponent from "@/components/TopBarComponent";
import SideNavComponent from "@/components/SideNavComponent";
import FooterComponent from "@/components/FooterComponent";
import {mapGetters} from "vuex";

export default {
  name: 'App',
  components: {TopBarComponent, SideNavComponent, FooterComponent},
  data() {
    return {
      welcomeShow: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    })
  },
  async created() {
    if (this.user) {
      await this.axios.get('/user')
        .then(async (res) => {
          let user = res.data.data;
          user.token = this.$store.state.auth.user.token;

          localStorage.setItem('user', JSON.stringify(user));
          this.$store.commit('auth/setUser', user);
        })
        .catch(() => {
          this.redirectLogin();
        })
    } else {
      this.redirectLogin();
    }
  },
  watch: {
    $route() {
      if (this.$route.name === 'login') {
        document.body.classList.add('account-body')
        document.body.classList.add('accountbg')
      } else {
        document.body.classList.remove('account-body')
        document.body.classList.remove('accountbg')
      }
    }
  },
  methods: {
    redirectLogin() {
      let url = new URL(window.location.href);
      let paramInvitation = url.searchParams.get("invitation");

      let query = {};

      if (paramInvitation) {
        query.invitation = paramInvitation;
        return true;
      }

      this.$store.dispatch('auth/resetUser');
      this.$router.push({name: 'login', query: query});
    },
  }
}
</script>


<style scoped>

.sidebar-theme {
  background: #181722;
  position: fixed;
  top: 0;
  height: 100%;
}

</style>