import auth from "@/middlewares/auth";
import guest from "@/middlewares/guest";

let routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard'),
    name: 'dashboard',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/profile',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/auth/Profile.vue'),
    name: 'profile',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/settings',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Settings.vue'),
    name: 'settings',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/invitations',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Invitations.vue'),
    name: 'invitations',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/auth/Login'),
    name: 'login',
    meta: {
      middleware: [
        guest
      ]
    }
  },
]
export default routes;
