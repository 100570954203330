<template>
  <div class="sidebar-wrapper sidebar-theme fixed-top">
    <div id="dismiss" class="d-lg-none"><i class="flaticon-cancel-12"></i></div>
    <nav id="sidebar">
      <ul class="navbar-nav theme-brand flex-row  d-none d-lg-flex">
        <li class="nav-item d-flex">
          <router-link :to="{name: 'dashboard'}" class="p-2">
            <img :src="baseUrl + 'assets/images/logo_influencer.png'" class="img-fluid brand-logo" alt="logo"  />
          </router-link>
        </li>
      </ul>
      <ul class="list-unstyled menu-categories" id="accordionExample">
        <li class="menu">
          <router-link :to="{name: 'dashboard'}" data-toggle="collapse" aria-expanded="false" class="nav-item">
            <div>
              <i class="flaticon-computer-6 ml-3"></i>
              <span>Dashboard</span>
            </div>
          </router-link>
        </li>
        <li class="menu">
          <router-link :to="{name: 'settings'}" data-toggle="collapse" aria-expanded="false" class="nav-item">
            <div class="">
              <i class="flaticon-settings-1"></i>
              <span>Settings</span>
            </div>
          </router-link>
        </li>
        <li class="menu">
          <router-link :to="{name: 'invitations'}" data-toggle="collapse" aria-expanded="false" class="nav-item">
            <div class="">
              <i class="flaticon-email"></i>
              <span>Invitations</span>
            </div>
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      settings: 'settings/getSettings',
      account: 'auth/getAccount'
    })
  },
  mounted() {
    window.$(".nav-item").on("click", function() {
      window.$(".dropdown-toggle").not(this).attr("aria-expanded", "false")
      window.$(".dropdown-toggle").not(this).addClass("collapsed")
      //remove show class from others
      window.$(".list-unstyled").not(window.$(this).next("ul")).removeClass("show")
    })

  }
}
</script>