import axios from "@/util/axios";

export default {
  namespaced: true,
  state () {
    return {
      user: JSON.parse(localStorage.getItem('user'))|| null,
    }
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
  },
  actions: {
    login({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/login', payload).then((response) => {
          let user = response.data.data;
          console.log(user);
          commit('setUser', user);
          localStorage.setItem('user', JSON.stringify(user));

          axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;

          resolve(user);
        }).catch((error) => {
          reject(error.response)
        });
      });
    },
    logout(context) {
      return new Promise( (resolve) => {
        axios.post('/logout')
          .finally(() => {
            context.dispatch('resetUser');
            resolve(true);
          });
      });
    },
    register({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/register', payload).then((response) => {
          if (response.data) {
            let user = response.data.data;

            commit('setUser', user);
            localStorage.setItem('user', JSON.stringify(user));

            axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;

            window.location.href = process.env.VUE_APP_BASE
          }

          resolve(response);
        }).catch((error) => {
          reject(error.response.data)
        });
      });
    },
    resetUser({commit}) {
      commit('setUser', null);
      localStorage.removeItem('user');
      axios.defaults.headers.common['Authorization'] = null;
    }
  },
  getters: {
    getUser: (state) => state.user,
  }
}